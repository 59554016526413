import React from 'react';
import { Link } from 'gatsby';
import withContext from 'store/withContext';

// import Button from 'components/Global/Button';

import PageWrapper from '../styles/creatorcup.css.js';

const creatorcup = (props) => {

  return (
    <PageWrapper>
      <main className="content">
        <section className="header">
          
        </section>
        <div className="center-wrapper">
        <section className="link-bar">
          <div className="link-wrapper">
            <Link to="/creatorcup#about">About the Creator Cup</Link>
            <Link to="/creatorcup#schedule">Schedule</Link>
            <Link to="/creatorcup#creators">Creators</Link>
            <Link to="/creatorcup#bracket">Brackets</Link>
            <Link to="/creatorcup#standings">Standings</Link>
          </div>
        </section>
          <section className="watch">
            <div id="about" className="copy-wrapper">
              <h6>The SMITE Content Creator Cup</h6>
              <p>The SMITE Content Creator Cup lets our SMITE content creators join with their communities to earn money for their favorite charities and have a once-in-a-lifetime live esports opportunity here at our studio in Alpharetta. The event is separated into four major groups: the Open Bracket phase, Group Stages, LAN Qualification, and the Content Creator Cup LAN finals.</p>
              <h6>Where To Watch</h6>
              <p>The SMITE Content Creator Cup is broadcast every weekend on our official SmiteGame streaming channels -- including <a href="https://www.twitch.tv/smitegame" target="_blank">Twitch</a>,<a href="https://www.youtube.com/smitegame" target="_blank ">YouTube</a>, and even <a href="https://steamcommunity.com/broadcast/watch/76561198835282120" target="_blank">Steam!</a></p>
            </div>
            
          </section>
          <section className="watch">
            <div id="schedule" className="copy-wrapper">
              <h5>Creator Cup Schedule</h5>
              <div style="padding-left:25px;">
              <h6>Open Bracket Schedule</h6>
              <div className="phase-container">
                <div> <strong>• EMEA Open Bracket Week 1 -- </strong> March 9th - March 10th, 2024</div>
                <div> <strong>• NA Open Bracket Week 1 -- </strong> March 16th - 17th, 2024</div>
                <div> <strong>• EMEA Open Bracket Week 2 -- </strong> March 23rd - March 24th, 2024</div>
                <div> <strong>• NA Open Bracket Week 2 -- </strong> April 6th - 7th, 2024</div>
                <div> <strong>• EMEA Open Bracket Week 3 -- </strong> April 13th - April 14th, 2024</div>
                <div> <strong>• NA Open Bracket Week 3 -- </strong> April 20th - 21st, 2024</div>
              </div>
              <h6>Group Stage Schedule</h6>
              <div className="phase-container">
                <div> <strong>• EMEA/NA Week 1 -- </strong> May 11th - 12th, 2024</div>
                <div> <strong>• EMEA/NA Week 2 -- </strong> May 18th - 19th, 2024</div>
                <div> <strong>• EMEA/NA Week 3 -- </strong> May 25th - 26th, 2024</div>
              </div>
              <h6>LAN Qualifier</h6>
              <div className="phase-container">
                <div> <strong>• EMEA Qualifier -- </strong> June 8th - 9th, 2024</div>
                <div> <strong>• NA Qualifier -- </strong> June 15th - 16th, 2024</div>
              </div>
              <h6>Creator Cup LAN Finals</h6>
              <div className="phase-container">
                <div> <strong>• LAN Finals Live from Alpharetta, GA -- </strong> July 13th - 14th, 2024</div>
              </div>
              </div>
            </div>
            
          </section>
          
          <section id="bracket" className="format">
            <strong>Creator Cup Brackets</strong>
            <p>Here are the brackets from the open bracket stage of the content creator cup:</p>
            <p><a href="https://challonge.com/SCCCNAW3" target="_blank">NA Week 3</a></p>
            <p><a href="https://challonge.com/SCCCEMEAW3" target="_blank">EMEA Week 3</a></p>
            <p><a href="https://challonge.com/SCCCNAW2" target="_blank">NA Week 2</a></p>
            <p><a href="https://challonge.com/SCCCEMEAW2" target="_blank">EMEA Week 2</a></p>
            <p><a href="https://challonge.com/SCCCNAW1" target="_blank">NA Week 1</a></p>
            <p><a href="https://challonge.com/SCCCEMEAW1" target="_blank">EMEA Week 1</a></p>
          </section>
          <section id="creators" className="format">
            <strong>Participating Creators</strong>
            <p>Be sure to check out all of your favorite content creators who are participating in the SMITE Content creator cup!</p>
            <div class="participants">
            <p><a href="https://twitch.tv/duduin_" target="_blank">Duduin</a></p>
            <p><a href="https://twitch.tv/idavy__" target="_blank">iDavy</a></p>
            <p><a href="https://twitch.tv/betty_smite" target="_blank">Betty</a></p>
            <p><a href="https://twitch.tv/puffy" target="_blank">Púffy</a></p>
            <p><a href="https://twitch.tv/mushashismite" target="_blank"></a></p>
            <p><a href="https://twitch.tv/seagylll" target="_blank">Seagyll</a></p>
            <p><a href="https://twitch.tv/pattedevelours" target="_blank">pattedevelours</a></p>
            <p><a href="https://twitch.tv/hayzer" target="_blank">Hayzer</a></p>
            <p><a href="https://twitch.tv/theotheranother" target="_blank">TheOtherAnother</a></p>
            <p><a href="https://twitch.tv/samdadude" target="_blank">SamDaDude</a></p>
            <p><a href="https://twitch.tv/sBennyQ" target="_blank">BennyQ</a></p>
            <p><a href="https://twitch.tv/faygoq" target="_blank">FaygoQ</a></p>
            <p><a href="https://twitch.tv/tempestinateacup" target="_blank">Tempestinateacup</a></p>
            <p><a href="https://twitch.tv/dishonoredsm" target="_blank">Dishonored</a></p>
            <p><a href="https://twitch.tv/xavien" target="_blank">Xavienn</a></p>
            <p><a href="https://Twitch.tv/Cardwelllegacy" target="_blank">CardwellLegacy</a></p>
            <p><a href="https://twitch.tv/kittau" target="_blank">Kittau</a></p>
            <p><a href="https://twitch.tv/bayroneri" target="_blank">Bayroneri</a></p>
            <p><a href="https://twitch.tv/renown__" target="_blank">CommoDoggo</a></p>
            <p><a href="https://twitch.tv/solo0rtroll" target="_blank">SoloOrTroll</a></p>
            <p><a href="https://twitch.tv/neyamavu" target="_blank">Neyamavu</a></p>
            <p><a href="https://twitch.tv/davion505" target="_blank">davion505</a></p>
            <p><a href="https://twitch.tv/nogankomar" target="_blank">Omarrrrrrrrrrr</a></p>
            <p><a href="https://twitch.tv/Snaddyy" target="_blank">Snaddy</a></p>
            <p><a href="https://Twitch.tv/khastik" target="_blank">khastik</a></p>
            <p><a href="https://twitch.tv/captaintwiggy" target="_blank">CaptainTwig</a></p>
            <p><a href="https://twitch.tv/rexuador" target="_blank">Ruedy</a></p>
            <p><a href="https://twitch.tv/hummu" target="_blank">hummu</a></p>
            <p><a href="https://twitch.tv/eddiewithaneye" target="_blank">Eddiewithaneye</a></p>
            <p><a href="https://twitch.tv/professoratatoskr" target="_blank">ProfessoRatatoskr</a></p>
            <p><a href="https://Twitch.tv/cojafox" target="_blank">cojafox</a></p>
            <p><a href="https://Twitch.tv/BaconCrumbz" target="_blank">BaconCrumbz</a></p>
            <p><a href="https://twitch.tv/skpeso" target="_blank">Skepso Skepso</a></p>
            <p><a href="https://twitch.tv/Rexsi" target="_blank">Rexsi</a></p>
            <p><a href="https://twitch.tv/brutalmagllinitime" target="_blank">Brutal Magllini Time</a></p>
            <p><a href="https://twitch.tv/jaytoofay" target="_blank">Jaytoofay</a></p>
            <p><a href="https://twitch.tv/xavanue" target="_blank">Xavanue</a></p>
            <p><a href="https://twitch.tv/toddfather" target="_blank">Toddfather</a></p>
            <p><a href="https://twitch.tv/legitimatt" target="_blank">LegitiMatt</a></p>
            <p><a href="https://twitch.tv/legit_xc" target="_blank">Legit_xc</a></p>
            <p><a href="https://twitch.tv/srgtkillerspark" target="_blank">SrgtKillerSpark</a></p>
            <p><a href="https://twitch.tv/jaqsea" target="_blank">Jaqsea</a></p>
            <p><a href="https://twitch.tv/Weak3n" target="_blank">Weak3n</a></p>
            <p><a href="https://twitch.tv/solodoublej" target="_blank">SoloDoubleJ</a></p>
            </div>
          </section>
          <section className="teams" id="standings">
            <strong> CREATOR CUP STANDINGS </strong>
            <p>
              During the open bracket phase teams accrue points based on their
              placement each week.
            </p>

            <div className="phase-container">
              <div> <strong>• 1st Place -- </strong> 100 Points</div>
              <div> <strong>• 2nd Place -- </strong> 60 Points</div>
              <div> <strong>• 3rd Place -- </strong> 40 Points</div>
              <div> <strong>• 4th Place -- </strong> 25 Points</div>
              <div> <strong>• 5th - 8th Place -- </strong> 10 Points</div>
              <div> <strong>• 9th - 16th Place -- </strong> 5 Points</div>
              <div> <strong>• 17th or lower -- </strong> 2 Points</div>
            </div>
            <div className="team-wrapper">
              <div className="teams-container">
                <div className="team-heading">
                  EMEA Standings
                </div>
                <div className="team">Team iDavy - 240</div>
                <div className="team">Team Seagyll - 130</div>
                <div className="team">Team Duduin - 100</div>
                <div className="team">Team TheOtherAnother - 80</div>
                <div className="team">Team Betty - 35</div>
                <div className="team">Team Hayzer - 35</div>
                <div className="team">Team pattedevelours - 30</div>
                <div className="team">Team Deathwalker - 25</div>
              </div>
              <div className="teams-container">
                <div className="team-heading">
                  NA Standings
                </div>
                <div className="team"> Team CaptainTwig - 202</div>
                <div className="team"> Team Ruedy - 200</div>
                <div className="team"> Team Dishonored - 65</div>
                <div className="team"> Team khastik - 65</div>
                <div className="team"> Team Skepso Skepso - 50</div>
                <div className="team"> Team hummu - 40</div>
                <div className="team"> Team BennyQ - 40</div>
                <div className="team"> Team CardwellLegacy - 20</div>
              </div>
            </div>
            
            <p>For full standings visit <a href="https://docs.google.com/spreadsheets/d/1oUeu-d8KSPDSYUUA9PFIfUq6SLxTxqbZP9ln9A2_FGE/edit#gid=1575793625" target="_blank">here</a>.</p>

          </section>
        </div>
      </main>
    </PageWrapper>
  );
};

export default withContext(creatorcup);
